/* eslint-disable react/no-danger */
import Select from 'react-select'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Button from './Button'
import { Line, RH } from '.'
import { Page, PageContent } from './Page'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'
import pagesConfig from '../../pagesConfig'
import injectIntl from '../lib/intl/injectIntl'
import { categories, getCategoriesKeys } from '../courses'
import { StyledTooltip } from './fields/HintTooltip'

const TABLE_BP = 940

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;

  display: flex;
  flex-direction: column;

  ${up(TABLE_BP)} {
    display: table;
  }

  td {
    padding: 32px 8px;
    text-align: left;
  }
  td[data-tags] {
    text-align: center;
  }
  td:first-child {
    padding: 32px 16px 32px 0;
    text-align: left;
    width: 10%;
    white-space: nowrap;
  }
  td:last-child {
    padding: 32px 0;
    text-align: right;
    width: 1%;
    white-space: nowrap;
  }
`

const Tr = styled.tr`
  ${({ theme: { colors }, withTopLine }) => css`
    border-top: ${withTopLine && 'solid 1px #d5d9e2'};
    border-bottom: solid 1px #d5d9e2;
    display: none;

    ${up(TABLE_BP)} {
      display: table-row;
    }
  `}
`

const Tag = styled.span`
  ${({ theme: { colors }, color = 'lightGreen' }) => css`
    background: ${colors[color] || color};
    color: white;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 13px;
    white-space: nowrap;
  `}
`

const isCourseFull = item =>
  item.applications && item.capacity <= item.applications.length

const getItemTag = item => {
  if (item.isCancelled) {
    return {
      text: 'Zrušeno',
      color: 'black',
    }
  }

  if (moment().isSameOrAfter(item.from)) {
    if (moment().isSameOrBefore(item.to)) {
      return {
        text: 'Probíhá',
      }
    }

    return {
      text: 'Proběhl',
    }
  }

  if (item.isProjectFull) {
    return {
      text: 'Naplněno projektem',
    }
  }

  if (isCourseFull(item)) {
    return {
      text: 'Naplněno',
    }
  }

  if (item.capacity - 3 < item.applications.length) {
    return {
      text: 'Poslední místa',
      color: 'red',
    }
  }

  return null
}

const ListingItems = ({
  items,
  withoutFirstLine,
  withoutTags,
  withOrangeButton,
  categoryConfig: { useQuantityInTable } = {},
  isHeadless,
}) => (
  <>
    <Table>
      <tbody>
        {items.map((item, index) => {
          const tag = getItemTag(item)

          const ButtonComponent = withOrangeButton
            ? Button.SecondaryOrange
            : Button.SecondaryGreen

          const disabled =
            item.isProjectFull ||
            item.isCancelled ||
            moment(item.from).isSameOrBefore(moment())

          const showSubstituteButton =
            isCourseFull(item) &&
            !item.isProjectFull &&
            !item.isCancelled &&
            moment(item.from).isAfter(moment())

          const link = {
            pageKey: Link.COURSE,
            slugs: { cs: item.id },
            asA: !!showSubstituteButton,
            href: `mailto:kurzy@a-t-c.cz?subject=Evidence náhradníka ke kurzu ${item.name}, v termínu ${item.fromToDesc}`,
            preferLang: 'cs',
          }
          if (isHeadless) {
            link.params = { headless: true }
          }

          const TooltipWrapperComponent = showSubstituteButton
            ? StyledTooltip
            : React.Fragment

          let tooltipProps
          if (showSubstituteButton) {
            tooltipProps = {
              title:
                'U tohoto kurzu / semináře evidujeme zájemce o místa náhradníků. V případě zájmu nás neváhejte kontaktovat na emailu kurzy@a-t-c.cz nebo na telefonním čísle 777 677 759.',
              arrow: true,
              placement: 'top',
              // open: true,
              leaveDelay: 600,
              interactive: true,
            }
          }

          const btn = (
            <TooltipWrapperComponent {...tooltipProps}>
              <span>
                <ButtonComponent
                  iconLeft="signUp"
                  paddingRight="14px"
                  paddingLeft="18px"
                  iconGap="8px"
                  link={link}
                  disabled={disabled}
                >
                  {showSubstituteButton ? 'Náhradník' : 'přihlásit se'}
                </ButtonComponent>
              </span>
            </TooltipWrapperComponent>
          )

          return (
            <React.Fragment key={item.id}>
              <Tr key={item.id} withTopLine={index === 0 && !withoutFirstLine}>
                <td>
                  <Title.Mini fontWeight="700">{item.name}</Title.Mini>
                  {item.subtitle && (
                    <Text whiteSpace="normal" lineHeight="18px">
                      {item.subtitle}
                    </Text>
                  )}
                </td>
                {useQuantityInTable && (
                  <td dangerouslySetInnerHTML={{ __html: item.quantityDesc }} />
                )}
                {!useQuantityInTable && (
                  <>
                    <td>{item.fromToDesc}</td>
                    <td>{item.place}</td>
                    <td>{item.price}</td>
                  </>
                )}
                {!withoutTags && (
                  <td data-tags>
                    {tag && <Tag color={tag.color}>{tag.text}</Tag>}
                  </td>
                )}
                <td>{btn}</td>
              </Tr>
              <RH mobile bp={TABLE_BP}>
                <Col alignSelf="center" alignItems="center">
                  {index === 0 && !withoutFirstLine && <Line />}
                  <Gap gap="28px" />

                  <Text responsive textAlign="center">
                    <Title.Mini
                      innerAs="span"
                      display="inline"
                      fontWeight="700"
                    >
                      {item.name}
                    </Title.Mini>
                    {!useQuantityInTable && (
                      <>
                        , {item.fromToDesc}, {item.place}, {item.price}
                      </>
                    )}
                    {useQuantityInTable && (
                      <div
                        dangerouslySetInnerHTML={{ __html: item.quantityDesc }}
                      />
                    )}
                  </Text>
                  <Gap gap="20px" />

                  {btn}
                  <Gap gap="18px" mobileGap="28px" />
                  <Line />
                </Col>
              </RH>
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  </>
)

export default ListingItems
